import { render, staticRenderFns } from "./ProductTileImageCarousel.vue?vue&type=template&id=2b7f5521&scoped=true&"
import script from "./ProductTileImageCarousel.vue?vue&type=script&lang=js&"
export * from "./ProductTileImageCarousel.vue?vue&type=script&lang=js&"
import style0 from "./ProductTileImageCarousel.vue?vue&type=style&index=0&id=2b7f5521&prod&lang=postcss&scoped=true&"
import style1 from "./ProductTileImageCarousel.vue?vue&type=style&index=1&id=2b7f5521&prod&lang=postcss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b7f5521",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ShopifyImage: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/ShopifyImage.vue').default,SwiperNav: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/swiper-nav/SwiperNav.vue').default,AppBadge: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/common/AppBadge.vue').default,ProductTileIconHeart: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/product-tile/ProductTileIconHeart.vue').default})
