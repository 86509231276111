
import SwiperClass, { Navigation, Manipulation } from "swiper";

import { tagableMixin } from "~/mixins";

const DIMENSION = "288";

export default {
  mixins: [tagableMixin],

  props: {
    maxDiscount: { type: [Number, String], default: 0 },
    priorityCampaign: { type: Object, default: () => {} },
    selectedVariant: { type: Object, default: () => {} },
    product: { type: Object, required: true },
    metafields: { type: Object, default: () => {} },
    position: { type: Number, default: 0 },
    listname: { type: String, default: "" },
    images: { type: Array, default: () => [] },
  },

  data() {
    return { showNav: false, swiper: null };
  },

  computed: {
    swiperClass() {
      return `${this.product.handle}-product-title-images`;
    },
    swiperNextNavClass() {
      return `${this.product.handle}-swiper-next`;
    },
    swiperPrevNavClass() {
      return `${this.product.handle}-swiper-prev`;
    },
    swiperBegining() {
      return this.swiper?.isBegining;
    },
    swiperEnd() {
      return this.swiper?.isEnd;
    },
    swiperOptions() {
      return {
        slidesPerGroup: 1,
        slidesPerView: 1,
        loop: false,
        nested: true,
        loopFillGroupWithBlank: false,
        modules: [Navigation, Manipulation],
        navigation: {
          nextEl: `.${this.swiperNextNavClass}`,
          prevEl: `.${this.swiperPrevNavClass}`,
        },
      };
    },
    isAustralianMade() {
      return this.metafields?.australian_made == 1;
    },
    quickship() {
      return this.metafields?.quickship;
    },
    saleTagBgColor() {
      return this.priorityCampaign?.saleColor;
    },
    saleTagText() {
      const saleTileTitle = this.priorityCampaign?.saleTileTitle ?? "";

      return this.maxDiscount
        ? saleTileTitle.replace(/__percentage__/g, this.maxDiscount)
        : saleTileTitle;
    },
    allImages() {
      return this.images;
    },
    wishlistIconProps() {
      return {
        listname: this.listname,
        position: this.position,
        product: this.product,
        selectedVariant: this.selectedVariant,
      };
    },
    tags() {
      const tags =
        typeof this.product.tags == "string"
          ? this.product.tags?.split(",")
          : this.product.tags;

      return tags ?? [];
    },
  },

  watch: {
    allImages(value) {
      if (value.length !== 0) {
        this.$nextTick(() => {
          this.swiper?.update?.();
        });
      }
    },
  },

  mounted() {
    this.swiper = new SwiperClass(
      `.swiper.${this.swiperClass}`,
      this.swiperOptions
    );
  },

  destroyed() {
    this.swiper?.destroy?.();
  },

  methods: {
    onMouseEnter(e) {
      if (this.allImages.length > 1) {
        this.showNav = true;
      }

      if (!this.swiper?.destroyed) {
        this.swiper?.slideNext?.();
      }
    },
    onMouseLeave() {
      this.showNav = false;
      if (!this.swiper?.destroyed) {
        this.swiper?.slidePrev?.();
      }
    },
    getOptions(image) {
      return {
        src: image?.w_288_h_288 ?? image?.src,
        webpSrc: image?.w_288_h_288_webp ?? image?.webpSrc,
        alt: this.product.title,
        widths: [DIMENSION],
        width: DIMENSION,
        height: DIMENSION,
      };
    },
  },
};
